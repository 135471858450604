<template>
  <!-- 会员列表 -->
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="会员列表"
    ></el-page-header>
    <div class="select-container">
      <div class="select">
        <div class="selectVal">
          <div class="text">查找会员：</div>
          <el-input
            placeholder="请输入会员名 / 会员手机号"
            v-model="inputData"
            clearable
          >
          </el-input>
        </div>
        <el-button
          type="primary"
          class="search"
          icon="el-icon-search"
          :disabled="buttonDisabled"
          @click="searchButton"
          >搜索</el-button
        >
        <el-button type="success" @click="allProduct">全部会员</el-button>
      </div>
      <div class="product-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          :data="tableData"
          border
          style="width: 100%; max-width: 100%"
          :cell-style="csllStyle"
        >
          <el-table-column
            fixed
            type="index"
            :index="pagesize * page_num - pagesize + 1"
            label="序号"
            width="80%"
          >
          </el-table-column>
          <el-table-column prop="cardNo" label="会员编号" width="190%">
          </el-table-column>
          <el-table-column prop="name" label="会员名" width="170%">
          </el-table-column>
          <el-table-column prop="contractPrice" label="合同金额" width="190%">
          </el-table-column>
          <el-table-column prop="vipLimit" label="余额" width="150%">
          </el-table-column>
          <el-table-column prop="phonenum" label="电话" width="180%">
          </el-table-column>
          <el-table-column prop="creator" label="创建者" width="190%">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="220%">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180%">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button type="text" @click="compile(scope.row)" size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页底部 -->
        <el-pagination
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next"
          :total="totalNum"
        >
        </el-pagination>
      </div>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import secret from "@/utils/secret.js";
export default {
  inject: ["reload"],
  data: function () {
    return {
      data: "",
      tableData: "",
      value: "title",
      inputData: "",
      buttonDisabled: true,
      // 是否加载数据
      loading: true,
      currentPage: 1,
      pagesize: 10,
      files_count: 5,
      fileList: [],
      searchFile: "",
      totalNum: 0,
      page_num: 1,
    };
  },
  created() {
    this.userKey = secret.Decrypt(localStorage.getItem("user_key"));
    let pageNum = 1;
    let list = true;
    let userKey = this.userKey;
    axios.post(this.GLOBAL.api + "/creat/vip", { list, pageNum, userKey }).then((res) => {
      this.loading = false;
      this.totalNum = res.data.total[0].count;
      this.tableData = res.data.val;
    });
  },
  // 监听器
  watch: {
    inputData: function () {
      this.buttonDisabled =
        this.inputData !== "" || this.inputData ? false : true;
    },
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    handleClick(row) {
      const id = row.cardNo;
      this.$router.push(`/fix/vip/${id}`);
    },
    handleCurrentChange(val) {
      let pageNum = val;
      this.page_num = val;
      let list = true;
      let userKey = this.userKey;
      axios.post(this.GLOBAL.api + "/creat/vip", { list, pageNum, userKey }).then((res) => {
        this.totalNum = res.data.total[0].count;
        this.tableData = res.data.val;
      });
    },
    searchButton() {
      const serch = true;
      const enterprice = this.inputData;
      let userKey = this.userKey;
      axios.post(this.GLOBAL.api + "/creat/vip", { serch, enterprice, userKey }).then((res) => {
        this.totalNum = res.data.total[0].count;
        this.tableData = res.data.val;
      });
    },
    compile(e) {
      this.$confirm("是否确定删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(() => {
        const cardNo = e.cardNo;
        const deleteData = true;
        let userKey = this.userKey;
        axios
          .post(this.GLOBAL.api + "/creat/vip", { deleteData, cardNo, userKey })
          .then((res) => {
            if (res.data.statusCode === 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.reload();
            } else {
              let pageNum = 1;
              let all = true;
              axios.post(this.GLOBAL.api + "/creat/contract", { all, pageNum, userKey }).then((res) => {
                this.loading = false;
                this.totalNum = res.data.total[0].count;
                this.tableData = res.data.val;
              });
            }
          });
      });
    },
    allProduct() {
      let pageNum = 1;
      let list = true;
      let userKey = this.userKey;
      axios.post(this.GLOBAL.api + "/creat/vip", { list, pageNum, userKey }).then((res) => {
        this.loading = false;
        this.totalNum = res.data.total[0].count;
        this.tableData = res.data.val;
      });
      this.inputData = ''
    },
    csllStyle(row) {
      // 监听tabel数据进行操作
      if (row.row.state !== '1') {
        return 'backgroundColor:rgb(167 41 41);color:#FFF'
      }
    }
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 0 1%;
}
.select {
  width: 100%;
  margin-top: 20px;
  display: flex;
}
.selectVal {
  display: flex;
  width: 35%;
  margin-left: 30px;
}
.product-table {
  width: 100%;
}
.search {
  margin-left: 10px;
}
.text {
  width: 35%;
  height: 100%;
  line-height: 250%;
}
.el-table {
  margin-top: 5%;
}
</style>